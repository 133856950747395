<template>
  <el-row type="flex" justify="center" align="center">
    <el-col :span="6">
      <h1>Home</h1>
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: 'Index',
  data() {
    return {}
  },
  created() {},
  methods: {}
}
</script>
